/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CollectionResponse, ItemResponse, PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type {
	ApolloCache,
	ApolloQueryResult,
	DefaultContext,
	FetchMoreOptions,
	FetchMoreQueryOptions,
	FetchResult,
	MutationFunctionOptions,
	OperationVariables,
	WatchQueryFetchPolicy,
} from "@apollo/client";
import type { ApolloError } from "@apollo/client/errors";
import type { GraphQLError } from "graphql";

type RefetchArgs<Type> = Partial<Type> | undefined;

export type FetchMoreArgs<Type> = { variables: RefetchArgs<Type> };

export type MutationFunction<TData, TVariables> = (
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	options?: MutationFunctionOptions<TData, TVariables, DefaultContext, ApolloCache<any>> | undefined,
) => Promise<FetchResult<TData>>;

export type SSRPageRequest<ReturnData> = {
	props: {
		apolloState: Record<string, unknown>;
		data: ReturnData;
		error: ApolloError | null | readonly GraphQLError[];
	};
};

export type SSRPageRepoReturn<ReturnData> = {
	apolloState?: Record<string, unknown>;
	data: ReturnData;
	error: ApolloError | null | readonly GraphQLError[];
};

export type RepoReturnType<RefetchArgs, FetchMoreArgs, ReturnTData> = {
	data: ReturnTData | undefined;
	error: ApolloError | GraphQLError | undefined;
	fetchMore?: (arg0: FetchMoreArgs) => Promise<ApolloQueryResult<ReturnTData>>;
	loading?: boolean | undefined;
	refetch?: (arg0: RefetchArgs) => Promise<ApolloQueryResult<ReturnTData>>;
};

export type RepoReturnTypeNoArgs<ReturnTData> = {
	data: ReturnTData | undefined;
	error: ApolloError | GraphQLError | undefined;
	loading?: boolean | undefined;
	refetch?: () => Promise<ApolloQueryResult<ReturnTData>>;
};

export enum TabsType {
	collections = "collections",
	products = "products",
}

export type TCategorySeo = {
	description: null | string;
	title: null | string;
};

export type MenuCategoryType = {
	id: number;
	other_category_category_parent_idTocategory: {
		slug: string;
		title: string;
	}[];
	slug: string;
	title: string;
};

export type ClientMenuCategoryType = {
	childCategories: {
		slug: string;
		title: string;
	}[];
	id: number;
	slug: string;
	title: string;
};

export type ManufacturerType = {
	slug: string;
	title: string;
};

export enum ProductStatus {
	available = "store.available", //отображается с пометкой что на заказ
	disabled = "store.disabled", //выключен полностью
	inStock = "store.in_stock", //стандартный товар
	published = "store.published", //отображается но нет кнопки купить
}
export enum CollectionStatus {
	archived = "showcase.archived", //архивная отображается только в производителях
	available = "showcase.available", //доступна
	disabled = "showcase.disabled", //отключены
}

export enum ThemeColor {
	accentBlue = "#00C2FF",
	accentGreen = "#00CC8F",
	accentPurple = "#7C70FF",
	accentRed = "#CC2200",
	accentYellow = "#FCB54A",
	backgroundColor = "#F3F4F7",
	darkGlass = "#06121380",
	darkGray = "#6A7171",
	greenHover = "#00B27D",
	lightGlass = "#F2F2F2CC",
	mediumGray = "#D4D8E2",
	primaryBlack = "#061213",
	white = "#FFFFFF",
}

export enum CollectionSamples {
	east = "Образец Восток",
	north = "Образец Север",
}

export type PropertyValue = {
	slug: string;
	title: string;
};

export type PropertyGroup = {
	id: number;
	title: string;
	values: PropertyValue[];
};

export type GroupedProperties = {
	[key: number]: PropertyGroup;
};

export type ItemCollectionViewProps = {
	categoryDescription?: null | string;
	categoryTitle: string;
	closeMobileFilters: () => void;
	collections?: CollectionResponse[];
	collectionsLoading: boolean | undefined;
	filters?: PropertyGroupResponse[];
	filtersLoading?: boolean;
	handleFetchMore: () => void;
	handleSelectFilter: (value: string, isChecked: boolean, title: string) => void;
	handleTabChange: (type: string | undefined) => void;
	hasNextPage: boolean;
	isProducts: boolean;
	listType: TabsType | undefined;
	mobileFiltersOpen: boolean;
	openMobileFilters: () => void;
	products?: ItemResponse[];
	productsLoading: boolean | undefined;
	resetFilters: () => void;
	selectedFilters: SelectedFilters;
	itemsPerPage?: number;
	asH2?: boolean;
};

export type SelectedFilters = {
	[key: string]: string[];
};

export type FiltersInputType = {
	brand?: null | string[];
	color?: null | string[];
	purpose?: null | string[];
	roomType?: null | string[];
	shape?: null | string[];
	size?: null | string[];
	surface?: null | string[];
};

export type TContactBlockType = "left" | "right";

export type TContactExpandedState = {
	open: boolean;
	type: TContactBlockType;
};

export type TProductProperty = {
	typeProperty: string;
	value: null | string;
};

export type BreadcrumbItem = {
	href?: string;
	name: string;
};

export type HookReturnType<TData, TVariables extends OperationVariables> = {
	data: TData | undefined;
	error: ApolloError | GraphQLError | undefined;
	fetchMore: (
		options: FetchMoreOptions<TData, TVariables> & FetchMoreQueryOptions<TVariables, TData>,
	) => Promise<ApolloQueryResult<TData>>;
	loading: boolean;
	refetch: (variables?: TVariables) => Promise<ApolloQueryResult<TData>>;
};

export type RepoMethodOptions<TData> = {
	fetchPolicy?: WatchQueryFetchPolicy;
	notifyOnNetworkStatusChange?: boolean;
	onCompleted?: (data: TData) => void;
	onError?: (error: ApolloError) => void;
	skip?: boolean;
};

export type TOrderTab = {
	key: TOrderTabItem;
	title: string;
	value: number;
};

export type TOrderTabItem = "metter" | "pack" | "unit";
